import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Container from '../components/Container'
import PageBody from '../components/PageBody'
import PageImage from '../components/PageImage'
import TagList from '../components/TagList'
import PostLinks from '../components/PostLinks'
import PostDetails from '../components/PostDetails'
import SEO from '../components/SEO'
import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const WaShare = styled.div`
  a {
    color: #25d366;
  }


`
const BlockShare = styled.div`
  text-align: center;
  padding: 1rem;
  > div {
    display: inline-block;
    padding: 0.5rem;
  }
  span {
    font-size: 1.6rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    vertical-align: middle;
  }
  a {
    text-decoration: none;
    font-size: 2rem;
  }
  i, svg {
    vertical-align: middle;
  }

`

const InstaView = styled.div`
  a {
    color: #8a3ab9;
  }

`






const PostTemplate = ({ data, pageContext, location }) => {
  const {
    title,
    slug,
    excerpt,
    thumbnail,
    image,
    body,
    publishDate,
    instagramUrl,
    tags
  } = data.contentfulChapter
  const postNode = data.contentfulChapter

  const itemPath = location.protocol + '//' + location.host + location.pathname

  const previous = pageContext.prev
  const next = pageContext.next
  
  const waText = 'https://wa.me/?text=%2A' + encodeURIComponent(title) + '%2A%0A%20_' + encodeURIComponent(excerpt) + '_%0A%0A' + 'Ikuti%20ceritanya%20di%20%2AKatekomika%2A' + '%0A' + encodeURI(itemPath)

  return (
    <Layout>
      <Helmet>
        <title>{`${title} - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO pagePath={slug} postNode={postNode} postSEO />
      <Container>
        <h1 className="page-title">{ title }</h1>
        {tags && <TagList tags={tags} />}
        <PostDetails
          date={publishDate}
          timeToRead={body.childMarkdownRemark.timeToRead}
        />
        <PageImage image={image} />
        <BlockShare>
          <WaShare className={`whatsapp-share`}>
            <a href={`${waText}`} target={`_blank`} rel={`noreferrer`}>
              <span>Share</span> 
              <FontAwesomeIcon icon={["fab", "whatsapp"]} style={{ 'color': '#25d366' }} />
            </a>
          </WaShare>
          {
            instagramUrl !== undefined &&
            <InstaView className={`instagram-view`}>
              <a href={instagramUrl} target={`_blank`} rel={`noreferrer`}>
                <FontAwesomeIcon icon={["fab", "instagram"]} style={{ 'color': '#8a3ab9' }} />
                <span>View</span> 
              </a>
            </InstaView>
          }
        </BlockShare>
        <PageBody body={body} />
      </Container>
      <PostLinks previous={previous} next={next} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulChapter(slug: { eq: $slug }) {
      title
      slug
      excerpt
      publishDate(formatString: "DD MMMM YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      topic {
        title
        slug
      }
      thumbnail {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }
      image {
        id
        sizes(maxWidth: 1280, maxHeight: 4000, quality: 100) {
          ...GatsbyContentfulSizes_withWebp_noBase64
        }
        file {
          url
        }
      }
      body {
        childMarkdownRemark {
          timeToRead
          html
          excerpt(pruneLength: 320)
        }
      }
      instagramUrl
    }
  }
`

export default PostTemplate




// <Hero title={title} image={thumbnail} height={'50vh'} />