import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

require('prismjs/themes/prism.css')

const Image = styled.div`
width: 100%;
img {
  width: 100%;
  max-width: 480px;
	margin: auto;
}
`


const PageImage = props => {
  return (
    <Image className="body-image">
      { props.image.map((image, i) => (
      	<div key={i}  className="image-wrapper"> 
					<img src={image.file.url} />
      	</div> 
        )
      )}
    </Image>
  )
}

export default PageImage

