import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Wrapper = styled.div`
  margin: -2em 0 0 0;
  padding: 0 1.5em 2em;
`

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  a {
    background: ${props => props.theme.colors.base};
    color: white;
    padding: 1em;
    border-radius: 2px;
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      background: ${props => props.theme.colors.secondary};
      color: #666;
      svg, i {
        color: #666;
      }
    }
    svg, i {
      transition: all 0.15s linear;
      font-size: 1.6rem;
      vertical-align:middle;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }
  }
`

const PreviousLink = styled(Link)`
  margin-right: auto;
  order: 1;
`

const NextLink = styled(Link)`
  margin-left: auto;
  order: 2;
`

const PostLinks = props => {
  return (
    <Wrapper>
      <Box>
            
        {props.previous && (
          <PreviousLink to={`/${props.previous.slug}/`}>
            <FontAwesomeIcon icon={["fas", "hand-point-left"]} style={{ }} />
            <span>Previous</span>
          </PreviousLink>
        )}
        {props.next && (
          <NextLink to={`/${props.next.slug}/`}>
            <span>Next</span>
            <FontAwesomeIcon icon={["fas", "hand-point-right"]} style={{ }} />
          </NextLink>
        )}
      </Box>
    </Wrapper>
  )
}

export default PostLinks
